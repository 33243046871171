<template>
  <div>
    <TitleMore
      :title="['灾后重建']"
      @handleMore="handleMore"
    ></TitleMore>
    <div class="baseCard homeThree" >		
     <el-carousel style="width:48%;" class="homeThree_carousel" >       
		<el-carousel-item style="height: 480px;"
          v-for="item in dataListBanner"
          :key="item.id"
        >
          <img 
            :src="item.smallImage+'!557x480'"
            @click="$goto('/detailShow',{id:item.id})"
            alt=""
          >
        </el-carousel-item>		
      </el-carousel>

      <div class="homeThreeRight">
        <div class="f-w-full f-h-full f-oh">
          <el-scrollbar>
            <div
              class="itemText"
              style="margin-bottom: 10px;"
              v-for="(item,index) in ListData"
              :key="index"
              @click="$goto('/detailShow',{id:item.id})"
            >
              <p>
                <img
                  src="../../assets/img/list-little.png"
                  alt=""
                >
                <span class="itemTextContent">{{item.title}}</span>
              </p>
              <p class="itemTextTime">{{item.publishDate}}</p>
            </div>
          </el-scrollbar>
        </div>
      </div>
    </div>
    <!-- <TitleMore
      :title="['滑坡','地震','泥石流','台风']"
      @handleMore="handleMore2"
      @handleTab="handleTab"
    ></TitleMore>
    <div class="baseCard rebuildCardMain" >
      <div
        class="rebuildCard"
        v-for="item in otherData"
        :key="item.id"
        @click="$goto('/detailShow',{id:item.id})"
      >
        <div style="width:45%" class="imgBox" v-if="item.smallImage">
          <img
            :src="item.smallImage+'!220x300'"
            alt=""
          >
        </div>
        <div
          class="rebuildCardContent"
          :style="{width:item.smallImage?'55%':'100%'}"
        >
          <p class="rebuildtitle" style="
          font-size: 20px;
          color: #D72829;
          font-weight:bold;
          text-align:center;margin-bottom:20px;line-height:30px">{{item.title}}</p>
          <p
            class="textsplices"
            style="
            font-size: 16px;
            color: #666666;
            line-height: 28px;"
          >
            {{item.metaDescription}}
          </p>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import TitleMore from "@components/TitleMore";
import TitleLine from "@components/TitleLine";
import apiUrls from "@api";
export default {
  data() {
    return {
      dataListBanner: [],
      ListData: [],
      otherData: [],
    };
  },

  components: {
    TitleMore,
    TitleLine,
  },

  mounted() {
    this.getList("zhcj_xwlb", 10);
    this.getList("zhcj_hp");
  },
  
  methods: {
    handleMore() {
      this.$router.push({
        path: "/imgDetail",
        query: { name: "灾后重建", type: "zhcj_xwlb" },
      });
    },
    handleMore2(index) {
      let name =
        index == 0
          ? "滑坡"
          : index == 1
          ? "地震"
          : index == 2
          ? "泥石流"
          : "台风";
      let type =
        index == 0
          ? "zhcj_hp"
          : index == 1
          ? "zhcj_dz"
          : index == 2
          ? "zhcj_nsl"
          : "zhcj_tf";
      this.$router.push({
        path: "/imgDetail",
        query: { name, type },
      });
    },
    handleTab(index) {
      let type =
        index == 0
          ? "zhcj_hp"
          : index == 1
          ? "zhcj_dz"
          : index == 2
          ? "zhcj_nsl"
          : "zhcj_tf";
      this.getList(type);
    },
    getList(node, size = 10) {
      apiUrls.selfDynamicList({ node, size }).then((res) => {
        if (node == "zhcj_xwlb") {
          let _this = this;
          res.results.data.forEach((item,ind)=>{
            if(_this.dataListBanner.length<3 && item.smallImage){
              this.dataListBanner.push(item)
            }else{
              this.ListData.push(item)
            }
          })
        } else {
          this.otherData = res.results.data;
          this.otherData.map((el) => {
            el.metaDescription = el.metaDescription.slice(0, 200) + "...";
            return el;
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.textsplice(@height:190px) {
  position: relative;
  line-height: 30px; //可变
  max-height: @height; //可变
  overflow: hidden;
  width: auto; //可变
}
.textsplices {
  .textsplice();
}
.el-scrollbar{
  height: 110%;

}
.el-scrollbar__wrap {

    overflow-x: hidden;
}
.homeThree {
	
  display: flex;
  height: 480px;
  .homeThree_carousel {	 
      position: relative;
	  .el-carousel__button {		 
	      border-radius: 50%;
	      width: 8px !important;
	      height: 8px !important;
	  }
	  .el-carousel__indicator {
	      width: 15px !important;		  
	  }
	  .el-carousel__indicator--horizontal {
	      //padding: 14px 8px !important; 
	      margin-right: 10px;		    
	   } 
	  .is-active .el-carousel__button {
	      width: 22px !important;
	      border-radius: 15px;
	      background-color: #D72829 !important;
	  }
       //padding-bottom: 20px;
       /*/deep/ .el-carousel__indicators {
        position: absolute;
        bottom: 35px !important;
        left: 50%;
        .el-carousel__indicator {
          width: 10px !important;
          height: 10px !important;
          border-radius: 50%;
          padding: 0px;
          background: #a0a0a0;
          margin: 0px 20px;
          .el-carousel__button {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            opacity: 0;
          }
        }
        .is-active {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #d1292e;
        }
      } */
    }
	
  .homeThreeRight {
    width: 48%;
    margin-left: 4%;
    padding-bottom: 20px;
    .itemText {
      padding: 10px 0px;
    }
    .htrContent {
      font-size: 14px;
      color: #958d8d;
      border-bottom: 1px dotted #dcdcdc;
      padding: 10px 0px;
      line-height: 30px;
    }
  }
}
.rebuildCardMain {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .rebuildCard {
	  &:hover {
	  		  transform: translateY(-5px);
	  		  box-shadow: 5px 5px 5px #ccc;
	  }
    width: 45%;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(58, 58, 58, 0.24);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;

    padding: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    .rebuildCardContent {
      width: 55%;
      margin-left: 20px;
    }
    .imgBox{
      overflow: hidden;
      height: 300px;
      img{
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }
}
.rebuildtitle {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.itemTextContent {
	&:hover {
		color: #d1292e;
	}
}
</style>